@use 'sass:math';

@use '../vars';
@use 'lineupengine/src/styles/vars' as le;

.#{vars.$lu_css_prefix}-renderer-dot {
  > div {
    background: vars.$lu_renderer_dot_color;
    opacity: vars.$lu_renderer_dot_opacity;
    border-radius: math.div(vars.$lu_renderer_dot_size, 2);
    width: vars.$lu_renderer_dot_size;
    height: vars.$lu_renderer_dot_size;
    margin-top: math.div(vars.$lu_renderer_dot_size, -2);
    margin-left: math.div(vars.$lu_renderer_dot_size, -2);
    top: 50%;
    position: absolute;
  }

  &.#{vars.$lu_css_prefix}-dot-single > div {
    background: vars.$lu_renderer_dot_single_color;
    opacity: vars.$lu_renderer_dot_single_opacity;
    border-radius: math.div(vars.$lu_renderer_dot_single_size, 2);
    width: vars.$lu_renderer_dot_single_size;
    height: vars.$lu_renderer_dot_single_size;
    margin-top: math.div(vars.$lu_renderer_dot_single_size, -2);
    margin-left: math.div(vars.$lu_renderer_dot_single_size, -2);
  }
}

.#{le.$engine_css_prefix}-td.#{vars.$lu_css_prefix}-renderer-dot[data-group='d'] {
  overflow-x: unset;
}
