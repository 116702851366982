@use 'sass:color';
@use '../vars';
@use '../icons/index' as icons;

@use 'search';
@use 'entry';
@use 'hierarchy';
@use 'ranking';

.#{vars.$lu_css_prefix}-side-panel {
  display: flex;
  flex-direction: column;
  width: 20em;
  transform: translate3d(0, 0, 1px); // separate layer
}

.#{vars.$lu_css_prefix}-stats {
  font-size: small;
  padding: 1em 0.5em;

  > span {
    padding: 2px;
    background-color: vars.$lu_selected_color;
  }
}

.#{vars.$lu_css_prefix}-stats-reset,
.#{vars.$lu_css_prefix}-stats-clear-selection {
  padding: 2px;
  font-style: normal;
  display: unset;
  color: vars.$lu_toolbar_color_base2;

  &:hover {
    color: vars.$lu_toolbar_color_hover;
  }

  > span {
    padding-left: 2px;
  }
}

.#{vars.$lu_css_prefix}-collapser {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 20;
  padding: 0.7em 0.2em;
  cursor: pointer;
  border-radius: 0.4em 0 0 0.4em;
  background: vars.$lu_toolbar_color_base;
  color: white;

  &:hover {
    background: color.adjust(vars.$lu_toolbar_color_base, $lightness: -10%);
  }

  &::before {
    @include icons.lu_icons();

    content: icons.$lu_icon_caret_right;
    width: 0.8em;
    text-align: center;
  }
}

.#{vars.$lu_css_prefix}-side-panel.#{vars.$lu_css_prefix}-collapsed {
  width: 0;
  transform: unset;

  > * {
    display: none;
  }

  .#{vars.$lu_css_prefix}-collapser {
    display: block;
    border-right: 1px solid vars.$lu_taggle_border_color;

    &::before {
      transform: scale(-1, 1);
    }
  }
}
