@use 'vars';

.#{vars.$lu_css_prefix}-aria,
.#{vars.$lu_css_prefix}-hidden {
  display: none !important;
}

.#{vars.$lu_css_prefix}-dragable {
  cursor: pointer;
  cursor: grab; // indicate grab able
}

.#{vars.$lu_css_prefix}-dragover {
  pointer-events: all !important;

  &::before,
  &::after {
    pointer-events: none !important;
  }

  > * {
    pointer-events: none !important;
  }
}

.#{vars.$lu_css_prefix}-checkbox {
  display: inline;

  > input {
    display: inline;
  }

  > span {
    display: inline-flex;
  }
}

.#{vars.$lu_css_prefix}-color-line {
  display: flex;
}

.#{vars.$lu_css_prefix}-checkbox-color {
  width: 1.2em;
  height: 1.2em;
  margin: 0.1em;
  line-height: 0;
  display: flex;

  input:checked + span {
    outline: 2px solid black;
  }

  > input {
    display: none;
  }

  > span {
    flex: 1 1 0;
  }
}

.#{vars.$lu_css_prefix}-color-gradient {
  display: flex;

  input:checked + span {
    outline: 2px solid black;
  }

  > span {
    margin: 0.1em;
    flex: 1 1 auto;
  }
}

.#{vars.$lu_css_prefix}-summary .#{vars.$lu_css_prefix}-checkbox > span {
  display: inline-flex;
}

.#{vars.$lu_css_prefix}-gradient-text {
  @include vars.user_select_none();

  position: absolute;
  overflow: hidden;
  inset: 0;
  pointer-events: none;
  z-index: 1;
}

.#{vars.$lu_css_prefix}-missing {
  background-image: linear-gradient(
    180deg,
    vars.$lu_missing_dash_color vars.$lu_missing_dash_height,
    transparent vars.$lu_missing_dash_height
  );
  background-position: center center;
  background-size: vars.$lu_missing_dash_width vars.$lu_missing_dash_height;
  background-repeat: no-repeat;

  > * {
    display: none !important;
  }
}

.#{vars.$lu_css_prefix}-busy * {
  cursor: wait !important;
}

.#{vars.$lu_css_prefix}-text-shadow {
  text-shadow: 0 0 1px white;
}
