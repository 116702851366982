@use 'sass:math';

@use 'vars';
@use 'icons/index';

.#{vars.$lu_css_prefix}-dialog-mapper {
  width: 25em;

  > .#{vars.$lu_css_prefix}-summary {
    height: 5em;
    margin: 0.5em 3px 0;
  }

  input[type='number'] {
    width: 5em;
  }

  &[data-scale='script'] .#{vars.$lu_css_prefix}-dialog-mapper-script {
    display: block;
  }
}

div.#{vars.$lu_css_prefix}-dialog-mapper-script {
  display: none;

  textarea {
    height: 5em;
    width: 98%;
    font-size: 9pt;
    display: block;
  }
}

.#{vars.$lu_css_prefix}-dialog-mapper-domain,
.#{vars.$lu_css_prefix}-dialog-mapper-range {
  transform: translate(0, 24px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 14px;
  border-bottom: 3px solid vars.$lu_mapping_box;
  padding: 0.4em 2px 20px;
  text-align: center;
}

.#{vars.$lu_css_prefix}-dialog-mapper-warning {
  padding: 0 0.4em;
  transform: translate(0, 10px);
}

.#{vars.$lu_css_prefix}-dialog-mapper-range {
  padding: 20px 2px 0.4em;
  transform: translate(0, -24px);
  border-bottom: none;
  border-top: 3px solid vars.$lu_mapping_box;
  justify-content: space-around;
}

svg.#{vars.$lu_css_prefix}-dialog-mapper-details {
  shape-rendering: geometricprecision;
  z-index: 1;
  height: math.div(25em * 6, 10);
  background: transparent;

  rect {
    fill: transparent;
    stroke: none;
  }

  line {
    stroke: vars.$lu_mapping_handle;
    stroke-width: 0.8;
    stroke-linejoin: round;
    stroke-linecap: round;

    &[data-v] {
      stroke-opacity: 0.5;
      stroke-width: 0.5;
      stroke: vars.$lu_mapping_line;
      pointer-events: none;
    }
  }

  circle {
    fill: vars.$lu_mapping_handle;
  }
}

g.#{vars.$lu_css_prefix}-dialog-mapper-mapping {
  cursor: ew-resize;

  > line:first-child {
    stroke-width: 5;
    stroke: transparent !important;
  }

  &.#{vars.$lu_css_prefix}-frozen circle:first-of-type {
    cursor: not-allowed;
    fill: vars.$lu_mapping_handle !important;
    transform: scale(0.4);
  }

  &.#{vars.$lu_css_prefix}-mapping-line-selected,
  &:hover {
    circle {
      fill: vars.$lu_mapping_hover;
    }

    line {
      stroke: vars.$lu_mapping_hover;
    }
  }

  &.#{vars.$lu_css_prefix}-frozen > .#{vars.$lu_css_prefix}-dialog-mapper-mapping-domain {
    display: none;
  }
}

.#{vars.$lu_css_prefix}-dialog-mapper-mapping-domain {
  pointer-events: none;
  font-size: 4px;
}

.#{vars.$lu_css_prefix}-dialog-mapper-mapping-range {
  pointer-events: none;
  font-size: 4px;
  dominant-baseline: hanging;
}

.#{vars.$lu_css_prefix}-dialog-mapper-mapping-right {
  text-anchor: end;
}

.#{vars.$lu_css_prefix}-dialog-mapper-mapping-middle {
  text-anchor: middle;
}

.#{vars.$lu_css_prefix}-dialog-mapper[data-scale='script'] div.#{vars.$lu_css_prefix}-dialog-mapper-script {
  display: block;
}
