@use 'sass:color';
@use 'vars';
@use 'icons/index' as icons;

.#{vars.$lu_css_prefix}-unsupported-browser {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > * {
    margin: 2em;
  }
}

.#{vars.$lu_css_prefix}-unsupported-browser-hint > a {
  text-decoration: none !important;
  position: relative;
  padding: 0.5em 1em;
  color: black;

  &::before {
    @include icons.lu_icons();

    font-size: 200%;
    transition: font-size 0.25s ease;
    color: color.adjust(black, $lightness: 50%);
  }

  &:hover::before {
    font-size: 250%;
  }

  &[data-browser='chrome']::before {
    content: icons.$lu_icon_chrome;
    color: black;
  }

  &[data-browser='firefox']::before {
    content: icons.$lu_icon_firefox;
  }

  &[data-browser='edge']::before {
    content: icons.$lu_icon_edge;
  }

  &::after {
    content: attr(data-version) '+';
    position: absolute;
    top: 100%;
    text-align: center;
    left: -1em;
    right: -1em;
  }
}
