@use 'vars';
@use 'icons/index' as icons;

.#{vars.$lu_css_prefix}-typed-icon {
  &[data-type-cat]::before {
    @include icons.lu_custom_icons();

    color: vars.$lu_toolbar_color_base2;
    width: 1.2em;
  }

  &[data-type-cat='composite']::before {
    @include icons.lu_custom_icon(icons.$lu_icon_type_composite, composite);
  }

  &[data-type-cat='map']::before {
    @include icons.lu_custom_icon(icons.$lu_icon_type_map, map);
  }

  &[data-type-cat='string']::before {
    @include icons.lu_custom_icon(icons.$lu_icon_type_string, string);
  }

  &[data-type-cat='categorical']::before {
    @include icons.lu_custom_icon(icons.$lu_icon_type_categorical, categorical);
  }

  &[data-type-cat='number']::before {
    @include icons.lu_custom_icon(icons.$lu_icon_type_numerical, numerical);
  }

  &[data-type-cat='date']::before {
    @include icons.lu_custom_icon(icons.$lu_icon_type_date, date);
  }

  &[data-type-cat='array']::before {
    @include icons.lu_custom_icon(icons.$lu_icon_type_matrix, matrix);
  }
}

.#{vars.$lu_css_prefix}-header {
  > .#{vars.$lu_css_prefix}-typed-icon[data-type-cat='support'],
  > .#{vars.$lu_css_prefix}-typed-icon[data-type-cat='other'] {
    padding: 0;

    &::before {
      display: none;
    }
  }
}
