@use '../vars';

.#{vars.$lu_css_prefix}-hierarchy {
  position: relative;
  order: 2;
}

.#{vars.$lu_css_prefix}-group-hierarchy,
.#{vars.$lu_css_prefix}-sort-hierarchy,
.#{vars.$lu_css_prefix}-sort-groups-hierarchy {
  counter-reset: hierarchy-counter;
  margin-bottom: 0.5em;

  &::before {
    content: '';
    display: block;
    font-size: small;
    color: vars.$lu_toolbar_color_base2;
    padding: 0.5em;
  }

  &:empty {
    display: none;
  }
}

.#{vars.$lu_css_prefix}-group-hierarchy {
  &::before {
    content: 'Grouping Hierarchy';
  }

  i[title='Group'] {
    pointer-events: none;
  }
}

.#{vars.$lu_css_prefix}-sort-hierarchy {
  &::before {
    content: 'Sorting Hierarchy';
  }
}

.#{vars.$lu_css_prefix}-sort-groups-hierarchy {
  &::before {
    content: 'Group Sorting Hierarchy';
  }
}

.#{vars.$lu_css_prefix}-hierarchy-entry,
.#{vars.$lu_css_prefix}-hierarchy-adder {
  padding: 6px 0.5em 0;
  display: flex;
  color: vars.$lu_toolbar_color_base2;
  border-top: 1px solid vars.$lu_side_panel_separator_color;
  font-size: vars.$lu_toolbar_font_size;
  font-weight: 500;
  counter-increment: hierarchy-counter;

  &::before {
    color: vars.$lu_toolbar_color_base2;
    padding-right: 0.2em;
    content: counter(hierarchy-counter);
  }
}

.#{vars.$lu_css_prefix}-hierarchy-entry {
  i {
    opacity: 0;
    transition: opacity 0.1s linear;
  }

  > .#{vars.$lu_css_prefix}-label {
    color: inherit;
    flex: 1 1 0;
  }

  &:hover {
    i {
      opacity: 1;
    }
  }

  i:first-of-type {
    padding: 0 4px !important;
    opacity: 1;

    &::after {
      display: none;
    }
  }

  &:first-of-type i[title='Move Up'],
  &:last-of-type i[title='Move Down'] {
    visibility: hidden;
  }
}

.#{vars.$lu_css_prefix}-hierarchy-adder > .#{vars.$lu_css_prefix}-search {
  color: inherit;
  flex: 1 1 0;

  > input {
    height: 19px;
    font-size: x-small;

    > ul {
      top: 19px;
    }
  }

  > ul {
    color: black;
    background: white;
  }
}
