@use 'sass:meta';
@use '../vars';
@use 'lineupengine/src/styles/vars' as le;
@use 'lineupengine/src/style';

.#{le.$engine_css_prefix}-th,
.#{vars.$lu_css_prefix}-nested-th {
  box-sizing: border-box;
  margin-right: vars.$lu_engine_grip_gap;
}

.#{le.$engine_css_prefix}-td {
  box-sizing: border-box;
}

.#{le.$engine_css_prefix}-footer {
  // extra space to the right using a width for easier last column resizing
  width: vars.$lu_engine_resize_space;
}

.#{vars.$lu_css_prefix}-resize-helper {
  display: none;
}

.#{vars.$lu_css_prefix}-resize-helper.#{vars.$lu_css_prefix}-resize-animated {
  transition: transform vars.$lu_engine_resize_animation_duration linear !important;
}

.#{vars.$lu_css_prefix}-resize-helper.#{vars.$lu_css_prefix}-resizing {
  display: unset;
}

@include meta.load-css('header');
@include meta.load-css('selection');
@include meta.load-css('row');
