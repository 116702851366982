// safari 10+ only
// see https://stackoverflow.com/questions/16348489/is-there-a-css-hack-for-safari-only-not-chrome#25975282

@use 'vars';
@use 'lineupengine/src/styles/vars' as le;

$lu_safari_check: 0.001dpcm;

@media not all and (min-resolution: $lu_safari_check) {
  @supports (-webkit-appearance: none) {
    .#{le.$engine_css_prefix}-header,
    .#{vars.$lu_css_prefix}-side-panel {
      transform: unset;
    }
  }
}
