@use '../vars';

.#{vars.$lu_css_prefix}-bar-label {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-variant-numeric: tabular-nums;

  > span.#{vars.$lu_css_prefix}-hover-only {
    position: relative; // required to position .lu-gradient-text
  }
}

.#{vars.$lu_css_prefix}-renderer-stack .#{vars.$lu_css_prefix}-renderer[data-renderer$='number'] > div {
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.#{vars.$lu_css_prefix}-group[data-renderer$='number'] > div > div {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
