@use '../vars';

.#{vars.$lu_css_prefix}-renderer-sparkline {
  height: 100%;
  display: block;

  > path {
    stroke: black;
    fill: none;
    vector-effect: non-scaling-stroke;
  }

  > line {
    stroke: vars.$lu_axis_color;
    stroke-width: 1;
    vector-effect: non-scaling-stroke;
  }
}
