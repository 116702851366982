@use '../vars';

.#{vars.$lu_css_prefix}-renderer-threshold {
  align-items: flex-start;

  > div {
    height: 50%;

    &.down {
      align-self: flex-end;
    }
  }
}
