@use '../vars';

.#{vars.$lu_css_prefix}-histogram {
  display: flex;
  flex-direction: row;

  &.#{vars.$lu_css_prefix}-histogram-i > .#{vars.$lu_css_prefix}-histogram-bin {
    cursor: pointer;
  }

  .#{vars.$lu_css_prefix}-checkbox {
    position: absolute;
    bottom: -45px;
  }

  &.#{vars.$lu_css_prefix}-dense {
    > .#{vars.$lu_css_prefix}-histogram-bin {
      margin: 0;
    }
  }
}

span.#{vars.$lu_css_prefix}-mapping-hint {
  @include vars.user_select_none();

  position: absolute;
  bottom: -15px;
  pointer-events: none;
  line-height: 100%;

  /*
    * create axis tick as background image and position it left or right
    */
  background-image: linear-gradient(to bottom, #000 3px, transparent);
  background-repeat: no-repeat;
  background-size: 1px 3px;
  padding-top: 5px;

  &:first-of-type {
    left: 0;
    background-position: left top;
  }

  &:last-of-type {
    right: 0;
    text-align: right;
    background-position: right top;
  }
}

.#{vars.$lu_css_prefix}-histogram-bin {
  flex: 1 1 auto;
  position: relative;
  margin: 0 1px 0 0;
  font-size: x-small;

  > div {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 0;
    width: 100%;
  }

  > .#{vars.$lu_css_prefix}-selected {
    background-color: vars.$lu_selected_color;
  }
}

.#{vars.$lu_css_prefix}-histogram:not(.#{vars.$lu_css_prefix}-dense)
  > .#{vars.$lu_css_prefix}-histogram-bin[data-title]::before,
.#{vars.$lu_css_prefix}-heatmap:not(.#{vars.$lu_css_prefix}-dense)
  > .#{vars.$lu_css_prefix}-heatmap-cell[data-title]::before {
  content: attr(data-title);
  position: absolute;
  bottom: -15px;
  left: 0;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
}

.#{vars.$lu_css_prefix}-histogram-min,
.#{vars.$lu_css_prefix}-histogram-max {
  position: absolute;
  top: 0;
  height: 100%;
  border-right: 1px solid black;
  transform: translate(-1px, 0);
  transition: border-width 0.2s ease;
  width: 1px;
  cursor: ew-resize;

  &::before {
    content: attr(data-value);
    position: absolute;
    bottom: -15px;
    width: vars.$lu_histogram_drag_hint_width;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.#{vars.$lu_css_prefix}-histogram-max {
  right: 0;
  border-right: none;
  transform: translate(1px, 0);
  border-left: 1px solid black;
}

.#{vars.$lu_css_prefix}-histogram-min.#{vars.$lu_css_prefix}-swap-hint,
.#{vars.$lu_css_prefix}-histogram-max:not(.#{vars.$lu_css_prefix}-swap-hint) {
  &::before {
    left: -(vars.$lu_histogram_drag_hint_width);
    text-align: right;
  }
}

.#{vars.$lu_css_prefix}-histogram-min-hint,
.#{vars.$lu_css_prefix}-histogram-max-hint {
  pointer-events: none;
  position: absolute;
  top: 0;
  height: 100%;
  background: repeating-linear-gradient(
    -45deg,
    rgb(255 255 255 / 70%),
    rgb(255 255 255 / 70%) 7px,
    #bababa 7px,
    #bababa 10px
  );
}

.#{vars.$lu_css_prefix}-histogram-max-hint {
  right: 0;
}

.#{vars.$lu_css_prefix}-histogram.#{vars.$lu_css_prefix}-histogram-i {
  margin-bottom: 3.75em;

  &:hover {
    > .#{vars.$lu_css_prefix}-histogram-min {
      border-right-width: 5px;
    }

    > .#{vars.$lu_css_prefix}-histogram-max {
      border-left-width: 5px;
    }
  }
}
