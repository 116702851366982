@use 'vars';
@use 'icons/index' as icons;
@use 'lineupengine/src/styles/vars' as le;

section.#{vars.$lu-css_prefix}-slopegraph {
  width: vars.$lu_slope_width;
  position: absolute;
}

section.#{vars.$lu-css_prefix}-slopegraph > svg {
  shape-rendering: geometricprecision;
  height: 100%;
  width: 100%;
  background: white;
}

path {
  &.#{vars.$lu-css_prefix}-slope {
    fill: none;
    stroke: vars.$lu_slope_color;
    stroke-width: vars.$lu_slope_stroke_width;
    stroke-linecap: round;

    &:hover,
    &.#{vars.$lu_css_prefix}-selected,
    &.#{le.$engine_css_prefix}-highlighted {
      z-index: 10;
      stroke: vars.$lu_selected_color;
    }
  }

  &.#{vars.$lu-css_prefix}-group-slope {
    fill: vars.$lu_slope_group_color;
    stroke: vars.$lu_slope_color;
    stroke-linejoin: round;

    &:hover,
    &.#{vars.$lu_css_prefix}-selected,
    &.#{le.$engine_css_prefix}-highlighted {
      z-index: 10;
      stroke: vars.$lu_selected_color;
      fill: vars.$lu_slope_group_selected_color;
    }
  }
}

section.#{vars.$lu-css_prefix}-slopegraph-header {
  display: flex;
  flex: 0 0 auto;
  padding-top: 1.4em;
  justify-content: center;
  width: vars.$lu_slope_width;

  i {
    padding: 2px 4px;
    transition: all 0.5s ease;
    cursor: pointer;
    color: vars.$lu_toolbar_color_base2;

    &:hover {
      color: vars.$lu_toolbar_color_hover;
    }

    &.active {
      color: vars.$lu_selected_color;
      cursor: not-allowed;
    }

    &::before {
      @include icons.lu_custom_icons();
    }

    &[title='Item']::before {
      @include icons.lu_custom_icon(icons.$lu_icon_slope_item, slope_item);
    }

    &[title='Band']::before {
      @include icons.lu_custom_icon(icons.$lu_icon_slope_band, slope_band);
    }
  }
}
