@use 'vars';

@use 'common';
@use 'icons/index';
@use 'dialogs';
@use 'mappingeditor';
@use 'header/index' as index6;
@use 'engine/index' as index2;
@use 'renderer/index' as index3;
@use 'slope_graph';
@use 'panel/index' as index4;
@use 'datatypes';
@use 'taggle/index' as index5;
@use 'supported_browser';
@use 'safari';

.#{vars.$lu_css_prefix} {
  position: relative;
  line-height: normal;

  > aside {
    float: right;
    display: flex;
    flex-direction: column;
    border-left: 1px solid vars.$lu_taggle_border_color;
    border-radius: 0;
    height: 100%;
  }

  > main {
    height: 100%;
  }

  &::after {
    content: '';
    clear: both;
  }
}
