@use '../vars';

.#{vars.$lu_css_prefix}-renderer-catdistributionbar {
  display: flex;
}

.#{vars.$lu_css_prefix}-distribution-bar {
  overflow: hidden;
  width: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  > span {
    position: absolute;
    left: 5px;
    right: 3px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
