@use '../vars';

.#{vars.$lu_css_prefix}-rtable {
  font-size: 60%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 0.2em;
}

.#{vars.$lu_css_prefix}-table-cell {
  overflow: hidden;
  min-height: 1.2em;
  text-overflow: ellipsis;
}

.#{vars.$lu_css_prefix}-renderer-mapbars > div[title] {
  position: relative;

  > div {
    position: absolute;
    top: 0;
    bottom: 0;
  }
}
