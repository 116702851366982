@use 'sass:math';

@use '../vars';

.#{vars.$lu_css_prefix}-renderer-cattick {
  > div {
    background: vars.$lu_renderer_tick_color;
    opacity: vars.$lu_renderer_tick_opacity;
    height: 100%;
    top: 0%;
    position: absolute;
    z-index: -1;
  }
}
