@use '../vars';

.#{vars.$lu_css_prefix}-renderer-categorical,
.#{vars.$lu_css_prefix}-renderer-brightness {
  display: flex;
  white-space: nowrap;
}

.#{vars.$lu_css_prefix}-group.#{vars.$lu_css_prefix}-renderer-brightness > div {
  display: flex;
  white-space: nowrap;
  width: 100%;
}

.#{vars.$lu_css_prefix}-cat-color {
  flex: 0 0 16px;
  line-height: 100%;
  margin-right: 0.5em;

  /* border-radius: 8px; */
}

.#{vars.$lu_css_prefix}-cat-label {
  width: 0; // set 0 width for firefox to have proper ...
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  align-self: center;
}
