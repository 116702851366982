// COPY TO index.ts, not using webpack to be ES6 compatible
$lu_hover_color: #ffe8be !default;
$lu_selected_color: #ffcf76 !default;

$lu_css_prefix: 'lu' !default;

$lu_dialog_font_size: 11pt !default;
$lu_dialog_mouse_region: 40px;
$lu_toolbar_font_size: 11pt !default;
$lu_toolbar_color_base: #c1c1c1 !default;
$lu_toolbar_color_base2: #999 !default;
$lu_toolbar_color_hover: black !default;

$lu_hover_button_color: #e5e5e5 !default;
$lu_hover_header_color: #f3f3f3 !default;
$lu_filtered_opacity: 0.2 !default;

$lu_drag_over: rgb(151 151 151 / 80%) !default;

$lu_header_background: #6d6c6c !default;

/* stylelint-disable */
$lu_body_font:
  10pt 'Helvetica Neue',
  helvetica,
  arial,
  sans-serif !default;
/* stylelint-enable */
$lu_body_text_hover_color: darkblue !default;

$lu_slope_width: 200px !default;
$lu_slope_color: darkgray !default;
$lu_slope_group_color: rgb(169 169 169 / 50%) !default;
$lu_slope_group_selected_color: rgb(255 165 0 / 50%) !default;
$lu_slope_stroke_width: 1 !default;

$lu_renderer_boxplot_box: #e0e0e0 !default;
$lu_renderer_boxplot_stroke: black !default;
$lu_renderer_boxplot_sort_indicator: #ffa500 !default;
$lu_renderer_boxplot_outlier: #e0e0e0 !default;

$lu_renderer_upset_color: #676767 !default;
$lu_renderer_upset_inactive_opacity: 0.1 !default;

$lu_renderer_dot_color: gray !default;
$lu_renderer_dot_size: 5px !default;
$lu_renderer_dot_opacity: 0.5 !default;

$lu_renderer_dot_single_color: $lu_renderer_dot_color !default;
$lu_renderer_dot_single_size: 7px !default;
$lu_renderer_dot_single_opacity: 1 !default;

$lu_renderer_tick_color: $lu_renderer_dot_color !default;
$lu_renderer_tick_size: 3px !default;
$lu_renderer_tick_opacity: $lu_renderer_dot_single_opacity !default;

$lu_hist_color: #c1c1c1;

$lu_engine_grip_gap: 5px !default;
$lu_engine_row_outline_width: 2px !default;
$lu_engine_resize_space: 50px !default;
$lu_engine_resize_animation_duration: 1000ms !default;

$lu_missing_dash_height: 2px !default;
$lu_missing_dash_width: 10px !default;
$lu_missing_dash_color: #a0a0a0 !default;

$lu_axis_color: #c1c1c1 !default;

$lu_mapping_box: $lu_toolbar_color_base !default;
$lu_mapping_line: $lu_hover_button_color !default;
$lu_mapping_handle: $lu_toolbar_color_hover !default;
$lu_mapping_hover: $lu_selected_color !default;

$lu_side_panel_bg_color: #f0f0f0 !default;
$lu_side_panel_toolbar_bg: #6d6c6c !default;
$lu_side_panel_input_border: #999 !default;
$lu_side_panel_input_border_radius: 3px !default;
$lu_side_panel_font_size: 14px !default;
$lu_side_panel_toolbar_font_size: 9pt !default;
$lu_side_panel_separator_color: #dedede !default;

$lu_aggregate_level_offset: 2px !default;
$lu_aggregate_level_width: 22px !default;
$lu_aggregate_square_bracket_width: 8px !default;
$lu_aggregate_square_bracket_stroke_color: #000 !default;
$lu_aggregate_square_bracket_stroke_width: 2px !default;
$lu_aggregate_hierarchy_width: 4px !default;
$lu_aggregate_hierarchy_stroke_width: 1px !default;
$lu_aggregate_hierarchy_color: #000 !default;

$lu_taggle_border_color: #6d6c6c !default;

$lu_assets: './assets' !default;

// $lu_use_font_awesome: true !default;
$lu_use_font_awesome: false !default;

// include font awesome files instead of manually adding them
$lu_include_font_awesome: false !default;

/**
 * Width of the drag handler text of the histogram filter
 */
$lu_histogram_drag_hint_width: 7em !default;

@mixin user_select_none() {
  user-select: none;
}
