@use '../vars';

.#{vars.$lu_css_prefix}-renderer-upset {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.#{vars.$lu_css_prefix}-upset-line {
  position: absolute;
  top: 0;
  height: 50%;
  transform: translateY(-1px);
  border-bottom: 3px solid vars.$lu_renderer_upset_color; // line
}

.#{vars.$lu_css_prefix}-upset-dot {
  border-radius: 50%;
  background-color: vars.$lu_renderer_upset_color;
  opacity: vars.$lu_renderer_upset_inactive_opacity;
  width: 15px;
  height: 15px;

  &.#{vars.$lu_css_prefix}-enabled {
    opacity: 1;
  }
}
