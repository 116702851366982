@use 'sass:math';

@use '../vars';

.#{vars.$lu_css_prefix}-renderer-boxplot {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.#{vars.$lu_css_prefix}-boxplot-whisker {
  // whiskers + contains everything
  position: relative;
  border-left: 1px solid vars.$lu_renderer_boxplot_stroke;
  border-right: 1px solid vars.$lu_renderer_boxplot_stroke;
  height: 90%;
  max-height: 30px;

  &::before {
    // whiskers line
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 50%;
    width: 100%;
    border-bottom: 1px solid vars.$lu_renderer_boxplot_stroke;
  }

  &[data-sort='min'] {
    border-left-color: vars.$lu_renderer_boxplot_sort_indicator;
  }

  &[data-sort='max'] {
    border-right-color: vars.$lu_renderer_boxplot_sort_indicator;
  }

  &[data-sort='q1'],
  &[data-sort='q3'] {
    > .#{vars.$lu_css_prefix}-boxplot-box {
      &::after {
        content: '';
        position: absolute;
        width: 1px;
        height: 100%;
      }
    }
  }

  &[data-sort='q1'] > .#{vars.$lu_css_prefix}-boxplot-box::after {
    left: -1px;
    border-left: 1px solid vars.$lu_renderer_boxplot_sort_indicator;
  }

  &[data-sort='q3'] > .#{vars.$lu_css_prefix}-boxplot-box::after {
    right: -1px;
    border-right: 1px solid vars.$lu_renderer_boxplot_sort_indicator;
  }

  &[data-sort='median'] > .#{vars.$lu_css_prefix}-boxplot-median {
    background-color: vars.$lu_renderer_boxplot_sort_indicator;
  }

  &[data-sort='mean'] > .#{vars.$lu_css_prefix}-boxplot-mean {
    background: repeating-linear-gradient(
      to bottom,
      transparent 0%,
      transparent 5%,
      vars.$lu_renderer_boxplot_sort_indicator 5%,
      vars.$lu_renderer_boxplot_sort_indicator 15%,
      transparent 15%,
      transparent 20%
    );
  }
}

.#{vars.$lu_css_prefix}-boxplot-box {
  // box
  position: absolute;
  top: 10%;
  height: 80%;
  background: vars.$lu_renderer_boxplot_box;
  outline: 1px solid vars.$lu_renderer_boxplot_stroke;
}

.#{vars.$lu_css_prefix}-boxplot-median {
  position: absolute;
  top: 10%;
  height: 80%;
  width: 1px;
  background: vars.$lu_renderer_boxplot_stroke;
}

.#{vars.$lu_css_prefix}-boxplot-mean {
  position: absolute;
  top: 10%;
  height: 80%;
  width: 1px;
  background: repeating-linear-gradient(
    to bottom,
    transparent 0%,
    transparent 5%,
    vars.$lu_renderer_boxplot_stroke 5%,
    vars.$lu_renderer_boxplot_stroke 15%,
    transparent 15%,
    transparent 20%
  );
}

/**
outlier
  */

.#{vars.$lu_css_prefix}-boxplot-outlier {
  position: absolute;
  top: 50%;
  width: vars.$lu_renderer_dot_size;
  height: vars.$lu_renderer_dot_size;
  margin: math.div(vars.$lu_renderer_dot_size, -2);
  background: vars.$lu_renderer_boxplot_outlier;
  border-radius: 50%;

  &[data-sort] {
    background: vars.$lu_renderer_boxplot_sort_indicator;
  }
}
