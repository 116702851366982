@use '../vars';

.#{vars.$lu_css_prefix}-heatmap {
  display: flex;
  flex-direction: row;
  align-content: stretch;
  align-items: stretch;
}

.#{vars.$lu_css_prefix}-heatmap-cell {
  flex: 1 1 auto;
  position: relative;

  &.#{vars.$lu_css_prefix}-missing {
    // transform the dash to a square
    background-size: vars.$lu_missing_dash_height vars.$lu_missing_dash_height;
  }
}

.#{vars.$lu_css_prefix}-heatmap:not(.#{vars.$lu_css_prefix}-dense) > div[data-title] {
  // special alignment of heatmap labels
  &:first-of-type::before {
    text-align: left;
  }

  &:last-of-type::before {
    text-align: right;
  }
}
