@use '../vars';

.#{vars.$lu_css_prefix}-renderer-rank {
  text-align: right;
  display: flex;
  justify-content: flex-end;
  font-variant-numeric: tabular-nums;
  align-items: center;
}

.#{vars.$lu_css_prefix}-group.#{vars.$lu_css_prefix}-renderer-rank {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}
