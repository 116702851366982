@use '../vars';

.#{vars.$lu_css_prefix}-search {
  position: relative;
  display: flex;

  &::before {
    content: '+';
    border-radius: 4px 0 0 4px;
    width: 1.2em;
    text-align: center;
    border: 1px solid vars.$lu_side_panel_input_border;
    box-sizing: border-box;
    border-right: none;
  }
}

.#{vars.$lu_css_prefix}-search-input {
  flex: 1 1 0;
  height: 22px;
  border: 1px solid vars.$lu_side_panel_input_border;
  box-sizing: border-box;
  border-radius: 0 4px 4px 0;
  padding: 3px 5px;
  outline: none;
}

.#{vars.$lu_css_prefix}-search-list {
  display: none;
  position: absolute;
  overflow: auto;
  z-index: 1000;
  left: 1.2em;
  top: 22px;
  max-height: 0;
  background: white;
  text-align: left;
  padding: 0;
  margin-top: -1px;
  transition: all linear 0.5s;
  list-style: none;
  border: 1px solid vars.$lu_side_panel_input_border;
  border-radius: 0 0 vars.$lu_side_panel_input_border_radius vars.$lu_side_panel_input_border_radius;

  &.#{vars.$lu_css_prefix}-search-empty::before {
    content: 'No results found';
  }

  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li > span {
    padding: 0.2em;
    display: block;
  }

  li.#{vars.$lu_css_prefix}-search-group {
    margin-bottom: 0.2em;

    > span {
      background: vars.$lu_side_panel_bg_color;
      text-transform: capitalize;
      padding-left: 0.4em;
      font-weight: bold;
    }
  }

  li.#{vars.$lu_css_prefix}-search-item > span {
    cursor: pointer;
    padding-left: 0.4em;
    pointer-events: auto;

    &:hover {
      background-color: #f8f8f8;
    }
  }

  li.#{vars.$lu_css_prefix}-search-highlighted > span {
    background-color: #f8f8f8;
  }

  li.#{vars.$lu_css_prefix}-hidden {
    display: none;
  }
}

.#{vars.$lu_css_prefix}-search-open {
  > input {
    border-radius: vars.$lu_side_panel_input_border_radius vars.$lu_side_panel_input_border_radius 0 0;
  }

  > ul {
    display: block;
    max-height: 70vh;
  }
}

.#{vars.$lu_css_prefix}-search-list li > .#{vars.$lu_css_prefix}-searchbox-summary-entry {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  > span {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > span:first-of-type {
    flex-grow: 1;
  }

  > span:last-of-type {
    font-size: 75%;
  }
}
