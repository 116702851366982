@use '../vars';
@use '../icons/index' as icons;

@use 'lineupengine/src/styles/vars' as le;

.#{le.$engine_css_prefix}-td.#{vars.$lu_css_prefix}-renderer-selection {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  &::before {
    @include icons.lu_icons_o();

    cursor: pointer;
    display: inline;
    content: icons.$lu_icon_square_o;
  }
}

.#{vars.$lu_css_prefix}-summary.#{vars.$lu_css_prefix}-renderer-selection {
  cursor: pointer;
  font-size: medium;
  text-align: center;
}

.#{le.$engine_css_prefix}-tr.#{vars.$lu_css_prefix}-selected > .#{vars.$lu_css_prefix}-renderer-selection::before,
.#{le.$engine_css_prefix}-td.#{vars.$lu_css_prefix}-group-selected::before {
  content: icons.$lu_icon_check_square_o;
}
