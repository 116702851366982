@use '../vars';

@use 'summary';
@use 'toolbar';

.#{vars.$lu_css_prefix}-header,
.#{vars.$lu_css_prefix}-side-panel-entry {
  @include vars.user_select_none();

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: white;

  &.#{vars.$lu_css_prefix}-dragging {
    > .#{vars.$lu_css_prefix}-label,
    > .#{vars.$lu_css_prefix}-toolbar,
    > .#{vars.$lu_css_prefix}-summary {
      opacity: 0.5;
    }
  }
}

.#{vars.$lu_css_prefix}-label {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.#{vars.$lu_css_prefix}-sublabel {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  font-size: smaller;
}

.#{vars.$lu_css_prefix}-spacing {
  flex: 1;
}

.#{vars.$lu_css_prefix}-summary {
  position: relative;
  font-size: x-small;
}
