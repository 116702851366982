@use '../vars';
@use 'lineupengine/src/styles/vars' as le;

.#{vars.$lu_css_prefix}-hr {
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 2px;
  margin: 0;
  background-color: vars.$lu_selected_color;
  border: none;
  transform-origin: left center;
  display: none;
  pointer-events: none;

  @include vars.user_select_none();

  &.#{vars.$lu_css_prefix}-selection-active {
    display: block;
    z-index: 1000;
  }
}

.#{le.$engine_css_prefix}-tbody.#{vars.$lu_css_prefix}-selection-active {
  @include vars.user_select_none();
}

.#{vars.$lu_css_prefix}-selection-indicator {
  float: right;
  width: 4px;
  left: 0;
  height: 100%;
  display: none;
  flex-direction: column;
  justify-content: flex-end;

  > canvas {
    background: #efefef;
  }

  &.#{vars.$lu_css_prefix}-some-selection {
    display: flex;
  }
}
