@use '../vars';
@use 'lineupengine/src/styles/vars' as le;

.#{le.$engine_css_prefix}-td.#{vars.$lu_css_prefix}-renderer-string {
  display: flex;
  align-items: center;
}

.#{vars.$lu_css_prefix}-group.#{vars.$lu_css_prefix}-renderer-string,
.#{vars.$lu_css_prefix}-group.#{vars.$lu_css_prefix}-renderer-link,
.#{vars.$lu_css_prefix}-group.#{vars.$lu_css_prefix}-renderer-date,
.#{vars.$lu_css_prefix}-group.#{vars.$lu_css_prefix}-renderer-group {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.#{vars.$lu_css_prefix}-summary.#{vars.$lu_css_prefix}-renderer-string,
.#{vars.$lu_css_prefix}-summary.#{vars.$lu_css_prefix}-renderer-link,
.#{vars.$lu_css_prefix}-summary.#{vars.$lu_css_prefix}-renderer-date,
.#{vars.$lu_css_prefix}-summary.#{vars.$lu_css_prefix}-renderer-group {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.#{vars.$lu_css_prefix}-annotate-input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.#{vars.$lu_css_prefix}-right {
  justify-content: flex-end;
  text-align: right;
}

.#{vars.$lu_css_prefix}-center {
  justify-content: center;
  text-align: left;
}
