@use 'sass:color';
@use '../vars';
@use '../icons/index' as icons;

@use 'lineupengine/src/styles/import_once' as le;

@include le.exports('lineupjs/taggle/switcher') {
  .#{vars.$lu_css_prefix}-rule-button-chooser {
    padding: 0.5em 0.5em 0 1em;
    flex: 0 0 auto;
    cursor: pointer;
    overflow: inherit;
    display: flex;
    align-items: center;
    position: relative;

    span {
      padding-left: 5px;
    }

    &.#{vars.$lu_css_prefix}-violated {
      .#{vars.$lu_css_prefix}-rule-violation {
        display: block;
      }

      &:hover {
        .#{vars.$lu_css_prefix}-rule-violation {
          opacity: 1;
          visibility: visible;
          transition-delay: 0s, 0.3s;
        }
      }

      &::after {
        @include icons.lu_icons();

        content: icons.$lu_icon_exclamation_triangle;
        margin-top: -1px;
        margin-left: 5px;
        color: #ffd700;
      }
    }
  }
}

.#{vars.$lu_css_prefix}-rule-violation {
  display: none;
  position: absolute;
  background: color.adjust(#ffd700, $lightness: 45%);
  border: 1px solid black;
  top: 110%;
  left: 5px;
  max-width: 96%;
  hyphens: manual;
  padding: 5px;
  z-index: 100;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  transition:
    visibility 0s ease 0.3s,
    opacity 0.3s ease 0s;

  &::before {
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid black;
    position: absolute;
    top: -6px;
    left: 29.4%;
  }
}
